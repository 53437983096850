.election {
  margin-top: 20px;
  padding-top: 10px;

  &.open {
    background-color: #d4deeb7c;
    border-radius: var(--component-radius);
  }

  &-line {
    margin: 0 15px;
    padding: 10px 20px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: #d4deeb;
    color: var(--dark-grey);
    border-radius: var(--component-radius);
    font-weight: 600;
  }

  &-actions {
    margin: 0;
    display: flex;
    align-items: center;
  }

  &-btn {
    color: var(--blue-t5);
    margin-right: 10px;
    transition: all 0.3s ease;

    &:hover {
      color: #000;
    }
  }

  &-table {
    margin: 15px 15px 0;
    box-shadow: var(--component-shadow-secondary);
    font-size: var(--bs-body-font-size);
  }

  &-fields {
    margin: 0 15px;
  }

  & tbody {
    text-align: right;
  }

  & td.disabled {
    background-color: var(--lighter-grey);
  }

  & .candidate input {
    height: 38px;
  }

  & input {
    border: none;
    text-align: right;

    &[type='text'] {
      text-align: left;

      &.col5 {
        text-align: right;
      }
    }
  }

  & .edit-btn {
    border: none;
  }
}
